import React from "react";
import michal from "../assets/michal2.jpg";
import srLogo from "../assets/srlogoht.png";

const TopHome = () => {
  return (
    <div name="topName" className="grid h-full md:grid-cols-2 md:h-screen">
      <div className="bg-white h-[50vh] md:h-screen w-full mt-[80px]">
        <img className="h-full object-cover w-full" src={michal} alt="" style={{ width: "100%" }} />
      </div>
      {/* <div className="bg-gradient-to-b from-[#AB68A2] to-[#6F3768] flex justify-center items-center h-full w-full">
        <img src={srLogo} alt="" style={{ objectFit: "cover", height: "40vh" }} />
      </div> */}
      <div className="bg-white flex justify-center items-center h-[50vh] md:h-screen w-full">
        <img
          className="p-4 md:mt-16 rounded-lg"
          src={srLogo}
          alt=""
          style={{ objectFit: "cover", width: "80%" }}
        />
      </div>
    </div>
  );
};

export default TopHome;
