import React from "react";
// import { AiFillStar } from "react-icons/ai";

const Services = () => {
  return (
    <div name="services" className="w-full pb-16 pt-10 lg:pt-20">
      {/* <h2 className="text-3xl md:text-4xl font-bold text-[#206EA6] tracking-widest md:font-normal text-center">
        Fotografia ślubna
      </h2> */}
      <p className="max-w-[1200px] mx-auto text-justify px-2 pb-8 leading-7 text-lg text-gray-800">
      <span className="font-bold">Planujecie wesele?</span> Szukacie filmowca, który uwieczni najpiękniejsze momenty, tworząc tym samym pamiątkę na lata?
To wyjątkowy dzień, którego nie da się ot tak powtórzyć, dlatego nie ma miejsca na amatorkę. Wybierając mnie macie pewność zabezpieczenia sprzętowego, staranności, oraz artystycznego spojrzenia na Wasz ślub.
Najwyższej jakości obraz zapewnia mi kamera Blackmagic, rejestrująca plastyczny, filmowy look w rozdzielczości 4K, oraz stabilizator Ronin, dzięki któremu ujęcia są płynne i estetyczne. Stawiam na nowoczesne, dynamiczne formy filmowe, obfite w kinowe kadry. 
Dźwięk nagrywany jest z mikrofonów kierunkowych oraz rejestratora audio Tascam, przez co w kościele nie zostanie utracone żadne słowo, a grana na sali weselnej muzyka, w filmie zachowa swą oryginalną, studyjną jakość. 
Posiadam niezbędne kwalifikacje upoważniające do filmowania w obiektach sakralnych. 
Pracuję na terenie całego kraju.
      </p>
      <div className="max-w-[1200px] mx-auto grid grid-cols-12 gap-6 pt-4 pb-6">
        {/* card nr 1 */}
        <div class="relative col-span-full md:col-span-4 h-full justify-around bg-[#1a5a88] shadow-xl md:rounded-lg p-5 text-indigo-50 font-bold">
          {/* <div class="absolute top-0 left-0 right-0 h-0.5 bg-blue-500" aria-hidden="true"></div> */}
          <div class="px-2 pt-5 pb-1 border-b border-blue-500">
            <header class="flex items-center mb-2">
              <div class="w-6 h-6 rounded-full flex-shrink-0 bg-gradient-to-tr from-blue-500 to-blue-300 mr-3">
                <svg class="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                  <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                </svg>
              </div>
              <h3 class="text-xl font-semibold">TELEDYSK ŚLUBNY</h3>
            </header>
            <div class="text-sm mb-2">
              Teledysk ślubny w rozdzielczości 4K (od przygotowań do pierwszego tańca / bloku
              muzycznego) o długości ok. 3 min.
            </div>

            <div class="text-gray-300 font-bold mb-4">
              <span class="text-3xl">1900</span>
              <span class="text-2xl"> PLN</span>
            </div>
          </div>
          <div class="px-5 pt-4 pb-5">
            <div class="text-xs font-semibold uppercase mb-4">Pakiet zawiera:</div>

            <ul>
              <li class="flex items-center py-1">
                <svg
                  class="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">ujęcia z powietrza</div>
              </li>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">
                  galeria internetowa, dzięki której można pobrać lub wysłać materiały gościom
                </div>
              </li>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">pendrive w eleganckim pudełku z grawerem</div>
              </li>
              <li class="flex items-center py-1">
                <svg
                  class="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">dojazd do 100 km</div>
              </li>
            </ul>
          </div>
        </div>
        {/* Card nr 2 */}
        <div class="relative col-span-full md:col-span-4 h-full bg-[#1a5a88] shadow-lg md:rounded-lg p-5 text-indigo-50 font-bold">
          {/* <div class="absolute top-0 left-0 right-0 h-0.5 bg-blue-500" aria-hidden="true"></div> */}
          <div class="px-5 pt-5 pb-0 border-b border-green-500">
            <header class="flex items-center mb-2">
              <div class="w-6 h-6 rounded-full flex-shrink-0 bg-gradient-to-tr from-green-500 to-green-300 mr-3">
                <svg class="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                  <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                </svg>
              </div>
              <h3 class="text-xl font-semibold">KRÓTKI FILM ŚLUBNY</h3>
            </header>
            <div class="text-sm mb-2 md:mb-8">
              Film ślubny w rozdzielczości 4K o długości ok.{" "}
              <span className="text-white italic border-b-2">30 min</span> (od przygotowań do
              oczepin).
            </div>

            <div class="text-gray-300 font-bold mb-4">
              <span class="text-3xl">3400</span>
              <span class="text-2xl"> PLN</span>
            </div>
          </div>
          <div class="px-5 pt-4 pb-5">
            <div class="text-xs font-semibold uppercase mb-4">Pakiet zawiera:</div>

            <ul>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">
                  nowoczesny teledysk ślubny (skrót filmu) o długości ok. 3 min
                </div>
              </li>
              <li class="flex items-center py-1">
                <svg
                  class="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">ujęcia z powietrza</div>
              </li>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">
                  galeria internetowa, dzięki której można pobrać lub wysłać materiały gościom
                </div>
              </li>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">pendrive w eleganckim pudełku z grawerem</div>
              </li>
              <li class="flex items-center py-1">
                <svg
                  class="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">dojazd do 100 km</div>
              </li>
            </ul>
          </div>
        </div>
        {/* Card nr 3 */}
        <div class="relative col-span-full md:col-span-4 h-full bg-[#1a5a88] shadow-lg md:rounded-lg p-5 text-indigo-50 font-bold">
          {/* <div class="absolute top-0 left-0 right-0 h-0.5 bg-blue-500" aria-hidden="true"></div> */}
          <div class="px-5 pt-5 pb-0 border-b border-fuchsia-500">
            <header class="flex items-center mb-2">
              <div class="w-6 h-6 rounded-full flex-shrink-0 bg-gradient-to-tr from-fuchsia-500 to-fuchsia-300 mr-3">
                <svg class="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                  <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                </svg>
              </div>
              <h3 class="text-xl font-semibold">PEŁNY FILM ŚLUBNY</h3>
            </header>
            <div class="text-sm mb-2 md:mb-8">
              Film ślubny w rozdzielczości 4K o długości ok.{" "}
              <span className="text-white italic border-b-2">60 min</span> (od przygotowań do
              oczepin).
            </div>

            <div class="text-gray-300 font-bold mb-4">
              <span class="text-3xl">3900</span>
              <span class="text-2xl"> PLN</span>
            </div>
          </div>
          <div class="px-5 pt-4 pb-5">
            <div class="text-xs font-semibold uppercase mb-4">Pakiet zawiera:</div>

            <ul>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">
                  nowoczesny teledysk ślubny (skrót filmu) o długości ok. 3 min
                </div>
              </li>
              <li class="flex items-center py-1">
                <svg
                  class="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">ujęcia z powietrza</div>
              </li>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">
                  galeria internetowa, dzięki której można pobrać lub wysłać materiały gościom
                </div>
              </li>
              <li class="flex">
                <svg
                  class="w-3 h-6 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">pendrive w eleganckim pudełku z grawerem</div>
              </li>
              <li class="flex items-center py-1">
                <svg
                  class="w-3 h-3 flex-shrink-0 fill-current text-green-500 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                </svg>
                <div class="text-sm">dojazd do 100 km</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="border-[#1a5a88] border-1 shadow-xl rounded-lg p-5 max-w-[1200px] mx-auto py-6 text-[#1a5a88]">
        <h4 className="text-center text-2xl md:text-sm pb-6 font-bold">OPCJE DODATKOWE</h4>
        <div className="md:flex gap-8 mx-auto">
          <ul className="flex-1 pl-6 text-left list-disc">
            {/* <li>Realizacji filmu ślubnego w starym stylu – kilkugodzinne nagranie</li> */}
            <li>
              Realizacja fabularyzowanego teledysku ślubnego – materiał rejestrowany wg ustalonego
              wcześniej scenariusza
            </li>
            <li>
              Filmowe zaproszenie ślubne – krótkie klipy zrealizowane wg ustalonego wcześniej
              scenariusza. Idealny sposób na dodatek do standardowego papierowego zaproszenia
            </li>
          </ul>
          <ul className="flex-1 pl-6 text-left list-disc">
            <li>
              Filmowy plener – sesja video odbywająca się w innym niż ślub terminie. Atrakcyjne
              ujęcia, które zostaną wykorzystane w teledysku ślubnym
            </li>
            <li>
              Możliwość dodatkowej usługi fotograficznej, dzięki współpracy z zaprzyjaźnionym
              fotografem
            </li>
            <li>
              Filmowe podziękowanie rodzicom – materiał rejestrowany wg ustalonego wcześniej
              scenariusza
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
