import React from "react";
import { BsFacebook, BsYoutube, BsInstagram } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-scroll";

const FooterBar = () => {
  return (
    <section name="foobar" className="w-full h-full relative pt-8 pb-6 bg-[#1a5a88]">
      <div class="container max-w-[1140px] mx-auto px-4">
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="col-span-2 text-center md:text-left">
            <h4 class="text-3xl fonat-semibold text-white pb-4">Zapraszam do kontaktu</h4>
            <h5 class="text-lg mt-0 mb-4 text-indigo-50">
              Znajdziesz nas również w social mediach:
            </h5>
            <div className="flex flex-row justify-around md:justify-start">
              <button className="bg-slate-300 shadow-lg font-normal h-12 w-12 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-0 md:mr-8 hover:bg-white duration-200 group">
                <a
                  href="https://www.facebook.com/SR.VIDEOS.FANPAGE/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsFacebook size={28} className="group-hover:text-[#3b5998] duration-200" />
                </a>
              </button>
              <button className="bg-slate-300 shadow-lg font-normal h-12 w-12 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-0 md:mr-8 hover:bg-white duration-200 group">
                <a
                  href="https://www.youtube.com/@sr_videos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsYoutube size={28} className="group-hover:text-red-700 duration-200" />
                </a>
              </button>
              <button className="bg-slate-300 shadow-lg font-normal h-12 w-12 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-0 md:mr-8 hover:bg-white duration-200 group">
                <a
                  href="https://www.instagram.com/sr_videos_filmowanie/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram size={28} className="group-hover:text-[#d62976] duration-200" />
                </a>
              </button>
              <button className="bg-slate-300 shadow-lg font-normal h-12 w-12 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-0 md:mr-8 hover:bg-white duration-200 group">
                <a
                  href="https://www.tiktok.com/@sr_videos_filmowanie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok size={28} className="group-hover:text-[#00f2ea] duration-200" />
                </a>
              </button>
            </div>
          </div>
          <div className="col-span-1">
            <ul className="list-none text-white">
              <li class="p-1 block uppercase text-md font-semibold mb-2">Linki do strony:</li>
              <li className="p-1 hover:text-orange-400 duration-200 hover:cursor-pointer">
                <Link to="topName" smooth={true} duration={500}>
                  GŁÓWNA
                </Link>
              </li>
              <li className="p-1 hover:text-orange-400 duration-200 hover:cursor-pointer">
                <Link to="about" smooth={true} duration={500}>
                  O MNIE
                </Link>
              </li>
              <li className="p-1 hover:text-orange-400 duration-200 hover:cursor-pointer">
                <Link to="services" smooth={true} duration={500}>
                  USŁUGI
                </Link>
              </li>
              <li className="p-1 hover:text-orange-400 duration-200 hover:cursor-pointer">
                <Link to="opinions" smooth={true} duration={500}>
                  OPINIE
                </Link>
              </li>
              <li className="p-1 hover:text-orange-400 duration-200 hover:cursor-pointer">
                <Link to="contact" smooth={true} duration={500}>
                  KONTAKT
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-1 flex justify-end">
            <ul className="list-none text-white">
              <li class="p-1 block uppercase text-md font-semibold mb-2">Dane kontaktowe:</li>
              <li className="p-1">Michał Szczepaniak</li>
              <li className="p-1">Niewolno 36/6</li>
              <li className="p-1">62-240 Niewolno</li>
              <li className="p-1 hover:text-green-400 duration-200">
                <a href="tel:691102424">tel.: 691-102-424</a>
              </li>
              <li className="p-2">NIP: 7842431419</li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-fuchsia-500" />
      </div>
    </section>
  );
};

export default FooterBar;
