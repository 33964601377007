import TopHome from "./components/TopHome";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import FooterBar from "./components/FooterBar";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Opinions from "./components/Opinions";
import Services from "./components/Services";

function App() {
  return (
    <div className="App">
      <Navbar />
      <TopHome />
      <About />
      <Home />
      <Services />
      <Opinions />
      <Contact />
      <FooterBar />
      <Footer />
    </div>
  );
}

export default App;
